import * as React from "react";

function OelMarker(props) {
  return (
    <svg
      width={22}
      height={35}
      viewBox="0 0 22 35"
      preserveAspectRatio={"none"}
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.533 11.816a10.564 10.564 0 00-10.58-10.58C5.107 1.242.363 5.986.355 11.834c0 1.089.166 2.138.473 3.125.36 1.156 10.205 19.201 10.205 19.201s9.584-17.94 9.91-18.867c.381-1.088.592-2.26.59-3.477z"
        fill="#5D4DC4"
      />
      <path
        clipRule="evenodd"
        d="M21.533 11.816a10.564 10.564 0 00-10.58-10.58C5.107 1.242.363 5.986.355 11.834c0 1.089.166 2.138.473 3.125.36 1.156 10.205 19.201 10.205 19.201s9.584-17.94 9.91-18.867c.381-1.088.592-2.26.59-3.477z"
        stroke="#FFFFFE"
        strokeWidth={0.48}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.65 16.095a6.08 6.08 0 108.597-8.6 6.08 6.08 0 00-8.598 8.6z"
        fill="#FFFFFE"
      />
      <path
        clipRule="evenodd"
        d="M6.65 16.095a6.08 6.08 0 108.597-8.6 6.08 6.08 0 00-8.598 8.6z"
        stroke="#FFFFFE"
        strokeWidth={2.52}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.574 11.8c0 .268.018.532.051.792l4.701-2.351L7.53 8.444l-2.94 2.94a6.317 6.317 0 00-.016.415zm1.837-4.474l1.12 1.118 3.001-3.002a6.352 6.352 0 00-4.12 1.884zm8.555-.472l-5.64 3.387 1.796 1.796 4.533-4.533a6.42 6.42 0 00-.689-.65zm2.293 4.084l-4.34 2.894 1.796 1.797 2.451-2.45a6.417 6.417 0 00.093-2.24zm-1.84 5.394l-.704-.703-2.388 2.388a6.352 6.352 0 003.092-1.684zm-2.5-2.5l-1.797-1.796-4.47 4.472c.246.224.511.428.79.613l5.477-3.288z"
        fill="#5D4DC4"
      />
    </svg>
  );
}

export default OelMarker;
