import * as React from "react";

function Mch(props) {
  return (
    <svg width={"auto"} height={120} viewBox="0 0 69 69" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.45 34.8c-.73.73-1.7 1.132-2.73 1.133h-.002a3.832 3.832 0 01-2.728-1.131 3.865 3.865 0 01.003-5.459l6.016-6.153c.921-.921 1.445-2.111 1.484-3.361.034-1.222-.405-2.354-1.236-3.186-.833-.833-1.965-1.273-3.189-1.235-1.247.037-2.436.561-3.35 1.474l-1.463-1.463c1.287-1.288 2.975-2.027 4.751-2.08 1.801-.05 3.474.6 4.715 1.84 1.238 1.238 1.892 2.912 1.841 4.711-.055 1.782-.794 3.47-2.08 4.756l-6.017 6.153a1.795 1.795 0 00-.012 2.54c.338.338.787.524 1.265.524h.001c.477 0 .928-.188 1.267-.526l7.971-7.972 1.464 1.463-7.97 7.972zM9.777 54.088h43.98v-2.07H9.777v2.07zm.03-5.522h1.282v-1.092h2.07v1.092h2.978v2.07h-6.33v-2.07zm14.13-9.772l9.507-9.509-2.262-2.261 1.464-1.464 7.198 7.198-1.464 1.463-2.26-2.259-9.508 9.507-.606-.606-7.613 7.613-1.464-1.463 7.613-7.613-.606-.606zM37.352 24.49l3.561 3.561-1.464 1.464-3.56-3.562 1.463-1.463zm2.911-2.911l3.561 3.561-1.464 1.464-3.56-3.561 1.463-1.464zm6.282 26.987v2.07H35.222v-2.07h2.855v-4.293h5.67v4.293h2.8zm-3.37-29.898l3.56 3.561-1.464 1.464-3.56-3.561 1.463-1.464zm4.704 31.965h2.07v-9.086h-2.07v9.086zm-1.795-34.875l3.562 3.561-1.464 1.463-3.56-3.561 1.462-1.463zM34.646.467C15.923.467.746 15.645.746 34.364c0 18.72 15.177 33.903 33.9 33.903 18.721 0 33.9-15.183 33.9-33.903 0-18.719-15.179-33.897-33.9-33.897z"
        fill="#fff"
      />
    </svg>
  );
}

export default Mch;
