import React from "react";

function Partners({ children }) {
  return (
    <div id="partnerLogos" style={{}}>
      {children}
    </div>
  );
}

export default Partners;
