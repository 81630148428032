import * as React from "react";

function CrowdCubeLogo(props) {
  return (
    <svg width={155} height={50} viewBox="0 0 100 29" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.57 16.762a2.834 2.834 0 00-2.551 1.591h5.104c-.473-.934-1.434-1.591-2.552-1.591zM23.925 16.835a2.842 2.842 0 111.306 5.531 2.842 2.842 0 01-1.306-5.531zM79.894 17C78.328 17 77 18.21 77 19.776c0 1.565 1.328 2.894 2.894 2.894a2.846 2.846 0 002.842-2.841A2.844 2.844 0 0079.894 17zM49.048 16.762a2.838 2.838 0 00-2.842 2.841 2.839 2.839 0 002.842 2.842c1.58 0 2.895-1.264 2.895-2.841 0-1.566-1.328-2.842-2.895-2.842z"
        fill="#942202"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.334.079L7.46 10.999A8.596 8.596 0 000 19.51a8.585 8.585 0 008.592 8.59h81.873a8.585 8.585 0 008.592-8.59V8.59c.013-5.197-4.566-9.21-9.723-8.512zM8.565 22.432c.842 0 1.606-.368 2.119-.96l2.157.13a4.72 4.72 0 01-8.999-2 4.727 4.727 0 014.723-4.722 4.72 4.72 0 014.276 2.723l-2.157.132a2.798 2.798 0 00-2.119-.96 2.837 2.837 0 00-2.841 2.842 2.84 2.84 0 002.841 2.815zm10.907-5.736a2.532 2.532 0 00-.46-.04c-1.447 0-2.645.895-2.816 2.46a6.76 6.76 0 00-.026.527v4.684h-1.895V14.88h1.895v1.039c.079-.091.145-.144.21-.223.619-.592 1.606-.934 2.619-.934.223 0 .447.013.657.052l-.184 1.882zm5.105 7.63a4.728 4.728 0 01-4.723-4.723 4.727 4.727 0 014.723-4.723 4.727 4.727 0 014.723 4.723 4.728 4.728 0 01-4.723 4.724zm16.827-.04H38.72l-1.815-6.63-1.803 6.63h-2.684l-2.565-9.393h2.065l1.856 7.13 1.947-7.13h2.381l1.947 7.13 1.855-7.13h2.066l-2.566 9.394zm12.381.04H51.89v-.947a4.728 4.728 0 01-7.565-3.776 4.727 4.727 0 014.723-4.723c1.066 0 2.04.355 2.842.948v-4.066l1.895-.197v12.762zm6.039-1.894c.842 0 1.605-.368 2.118-.96l2.158.13a4.721 4.721 0 01-4.276 2.725 4.73 4.73 0 01-4.724-4.724 4.728 4.728 0 014.724-4.723 4.72 4.72 0 014.276 2.723l-2.158.132a2.796 2.796 0 00-2.118-.96 2.838 2.838 0 00-2.842 2.842c0 1.552 1.276 2.815 2.842 2.815zM73.638 24.3h-1.894v-.842c-.592.632-1.487.934-2.355.934a4.234 4.234 0 01-4.237-4.236V14.88h1.895v5.263c.013.144.013.276.039.408a2.347 2.347 0 002.316 1.934c1.092 0 1.986-.737 2.276-1.737a2.2 2.2 0 00.079-.605V14.88h1.894v9.42h-.013zm6.276.092a4.728 4.728 0 01-2.842-.947v.855h-1.868v-4.342c0-.091-.013-.184-.013-.29 0-.104 0-.183.013-.288V8.275l1.868-.21v7.828a4.728 4.728 0 017.565 3.776 4.72 4.72 0 01-4.723 4.723zm15.34-4.262h-7.472a2.828 2.828 0 002.789 2.302c.881 0 1.657-.395 2.171-1.026l2.013.38a4.729 4.729 0 01-4.184 2.527 4.728 4.728 0 01-4.724-4.723 4.728 4.728 0 014.724-4.723 4.727 4.727 0 014.723 4.723c0 .184-.013.368-.04.54z"
        fill="#942202"
      />
    </svg>
  );
}

export default CrowdCubeLogo;
