import * as React from "react";

function Wd2(props) {
  return (
    <svg width={""} height={120} viewBox="0 0 69 69" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.124 52.253h2.07V15.82h-2.07v36.433zM51.05 20.792h-2.594a2.242 2.242 0 01-1.988 1.196 2.205 2.205 0 01-1.967-1.196H26.469a2.244 2.244 0 01-1.988 1.196 2.205 2.205 0 01-1.968-1.196h-4.901v-2.07h4.918c.104-.195.237-.377.398-.538a2.235 2.235 0 013.157-.013c.166.166.3.352.402.551h18.03a2.3 2.3 0 01.398-.538 2.236 2.236 0 013.158-.013c.166.166.299.352.401.551h2.576v2.07zm0 7.746h-6.64a2.242 2.242 0 01-2.043 1.31 2.205 2.205 0 01-1.566-.646 2.178 2.178 0 01-.453-.664h-1.749a2.238 2.238 0 01-2.042 1.31 2.196 2.196 0 01-2.019-1.31H17.612v-2.069h17.063a2.236 2.236 0 013.487-.438c.134.133.245.282.337.438h1.985a2.237 2.237 0 013.488-.438c.133.132.244.28.337.438h6.741v2.069zm0 7.747H30.502a2.245 2.245 0 01-1.902 1.082l-.019.001c-.592 0-1.148-.23-1.565-.648h-.001a2.236 2.236 0 01-.335-.435h-9.068v-2.07h8.965a2.236 2.236 0 012.025-1.313h.019a2.199 2.199 0 012.021 1.313H51.05v2.07zm0 7.746h-3.96a2.262 2.262 0 01-1.971 1.196l-.018.001a2.198 2.198 0 01-1.966-1.197h-3.622a2.284 2.284 0 01-.397.536c-.421.421-.98.656-1.573.66l-.018.001a2.198 2.198 0 01-1.967-1.197h-2.197a2.23 2.23 0 01-.397.536 2.23 2.23 0 01-1.572.66l-.019.001a2.2 2.2 0 01-1.966-1.197H17.612v-2.07h11.811c.102-.194.235-.375.398-.538.874-.873 2.291-.88 3.157-.014.166.166.3.353.402.552h2.194c.103-.194.235-.375.398-.538a2.237 2.237 0 013.158-.014c.166.167.299.353.401.552h3.619c.103-.194.235-.375.398-.538a2.237 2.237 0 013.158-.014c.164.165.299.351.402.552h3.942v2.07zm-37.583 8.222h2.07V15.82h-2.07v36.433zM34.331.468C15.609.468.431 15.645.431 34.365c0 18.719 15.178 33.902 33.9 33.902 18.722 0 33.899-15.183 33.899-33.902C68.23 15.645 53.053.468 34.331.468z"
        fill="#fff"
      />
    </svg>
  );
}

export default Wd2;
